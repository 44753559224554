<template>
  <div id="app">
    <nav class="navbar  navbar-light" style="border-bottom: #bdc3c7 solid 0px">
      <div class="container">
        <a class="navbar-brand col" @click="backToMainSite()"
          ><img style="max-width: 100%" src="/images/logo_emotivi.png" alt="" />
          &nbsp;
        </a>
        <a
          v-if="
            $store.state.authenticated &&
              $store.state.affiliatedUser.establishments[0].es_avatar
          "
          class="navbar-brand col"
          style=" text-align:center;"
          @click="backToMainSite()"
          ><img
            style="max-width: 200px;width:100%;"
            :src="
              `${this.$config.server_url}${$store.state.affiliatedUser.establishments[0].es_avatar}`
            "
            alt=""
            class="logoPartner"
          />
        </a>
        <a
          v-if="
            $store.state.authenticated &&
              !$store.state.affiliatedUser.establishments[0].es_avatar
          "
          style="max-width: 80%"
          class="navbar-brand namePartner col"
          @click="backToMainSite()"
        >
          {{ $store.state.affiliatedUser.establishments[0].es_name }}
        </a>

        <!-- <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="navbar-toggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <div class=" justify-content-end col" id="navbarsExample04">
          <!-- <div class="row text-center justify-content-center">
            <div class="d-flex flex-wrap">
              <div class="">
                <a class="nav-link" href="/home">PRESENTATION</a>
              </div>
            
              <div class="">
                <a class="nav-link" @click="gotoPart('support', '')">SUPPORT</a>
              </div>
              <div class="">
                <a class="nav-link" @click="gotoPart('contact', '')">CONTACT</a>
              </div>
              <div class="">
                <a class="nav-link" @click="gotoPart('press', '')">PRESSE</a>
              </div>
            </div>
          </div> -->
          <!-- <div
            v-if="
              $route.name !== 'subscriptionaffiliate' &&
              $route.name !== 'loginaffiliate'
            "
            class="row justify-content-center"
            style="min-width: 160px"
          >
            <div class="col text-center">
              <button
                type="button"
                style="max-height: 38px"
                class="
                  btn btn-xl btn-emotivi
                  d-flex
                  justify-content-center
                  align-items-center
                "
                @click="backToMainSite()"
              >
                <img
                  style="max-width: 32px"
                  src="/android-chrome-192x192.png"
                />
                <span class="ml-1">emotivi.fr</span>
              </button>
            </div>
          </div> -->
          <div
            v-if="
              $store.state.connected &&
                $route.name !== 'subscriptionaffiliate' &&
                $route.name !== 'loginaffiliate' &&
                $route.name !== 'subscriptionlimitedperiod'
            "
            class="row justify-content-center"
            style="min-width: 200px"
          >
            <div class="col-6 col-lg-9 text-right p-1">
              <button
                type="button"
                class="btn btn-xl btn-primary"
                @click="goToAccount('/private/home')"
              >
                MON COMPTE
              </button>
            </div>
            <div class="col-6 col-lg-3 p-1">
              <button type="button" class="btn btn-primary" @click="logout">
                <icon
                  scale="1.2"
                  name="sign-out-alt"
                  style="cursor: pointer; fill: white"
                ></icon>
              </button>
            </div>
          </div>
          <!-- <div
            v-else-if="
              $route.name !== 'subscriptionaffiliate' &&
                $route.name !== 'loginaffiliate'
            "
            class="row justify-content-center"
            style="min-width: 160px"
          >
            <div class="col text-center">
              <button
                type="button"
                class="btn btn-xl btn-primary"
                @click="goToAccount()"
              >
                Se&nbsp;connecter
              </button>
            </div>
          </div> -->
          <div
            v-if="
              ($route.name === 'subscriptionaffiliate' ||
                $route.name === 'loginaffiliate') &&
                $store.state.authenticated
            "
            class="row justify-content-center"
            style="min-width: 160px"
          >
            <div class="col text-center">
              <button
                type="button"
                class="btn btn-xl btn-primary"
                @click="logoutAffiliate()"
              >
                Se déconnecter
              </button>
            </div>
          </div>

          <!-- <ul class="navbar-nav row">
            <li class="nav-item col-md-2" style="padding-top: 0;">
              <a class="nav-link" @click="gotoPart('home', 'part_presentation')"
                >PRESENTATION</a
              >
            </li>
            <li class="nav-item col-md-2" style="padding-top: 0;">
              <a class="nav-link" @click="gotoPart('home', 'part_faq')">FAQ</a>
            </li>
            <li class="nav-item col-md-2" style="padding-top: 0;">
              <a class="nav-link" @click="gotoPart('support', '')">SUPPORT</a>
            </li>
            <li class="nav-item col-md-2" style="padding-top: 0;">
              <a class="nav-link" href="mailto:contact@emotivi.io">CONTACT</a>
            </li>
            <li class="nav-item col-md-2" style="padding-top: 0;">
              <a class="nav-link" @click="gotoPart('press', '')">PRESSE</a>
            </li> -->

          <!-- <li
              class="nav-item nav-item-button mr-2"
              id="btn_co"
              style="padding-top: 0px;"
            >
              <div v-if="$store.state.connected" class="d-flex">
                <button
                  type="button"
                  class="btn btn-xl btn-primary mr-2"
                  @click="$router.push('/private/account')"
                >
                  Mon compte
                </button>
                <icon
                  scale="2"
                  name="sign-out-alt"
                  @click="logout"
                  style="cursor:pointer; color:#007bff;"
                ></icon>
              </div>
              <div v-else>
                <button
                  type="button"
                  class="btn btn-xl btn-primary"
                  @click="$router.push('/login')"
                >
                  Se&nbsp;connecter
                </button>
              </div>
            </li> -->
          <!-- </ul> -->
        </div>
      </div>
    </nav>

    <h1 v-if="$store.state.authenticated && $store.state.affiliatedUser">
      Bonjour cher Partenaire
    </h1>
    <div class="">
      <router-view />
    </div>

    <!-- <footer>
      <div class="container">
        <div class="row" style="padding: 20px 0 50px 0">
          <div class="col-sm-6 col-lg-4 text-center">
            <img src="/images/ico_bottom_1.png" alt="" class="" />
            <p>
              <b>Adresse</b><br />1501, route des Dolines<br />06560
              Sophia-Antipolis
            </p>
          </div>
          <div class="col-sm-6 col-lg-4 text-center">
            
            <img src="/images/ico_bottom_3.png" alt="" class="" />
            <p>
              <b>Contact commercial</b><br /><a
                href="/contact?service=commercial"
                style="color: white"
                >contact@emotivi.io</a
              >
            </p>
          </div>
          <div class="col-sm-6 col-lg-4 text-center">
            <img src="/images/ico_bottom_3.png" alt="" class="" />
            <p>
              <b>Contact technique</b><br /><a
                href="/contact?service=technique"
                style="color: white"
                >support@emotivi.io</a
              >
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col text-center mb-3">
            <a
              target="_black"
              href="/files/CGU-Emotivi-20201209.pdf"
              style="color: white"
              >Voir les CGU</a
            >
          </div>
        </div>
      </div>
    </footer> -->

    <div id="wincookie" style="display: none">
      <div style="float: left; cursor: pointer; margin-right: 20px">
        <a href="javascript:void(0);" onclick="closeCookieWin();"
          ><img src="/images/bt_fermer.png" alt=""
        /></a>
      </div>
      En poursuivant votre navigation sans modifier vos paramètres, vous
      acceptez l'utilisation des cookies ou technologies similaires pour
      disposer de services et d'offres adaptés à vos centres d'intérêts ainsi
      que pour la sécurisation des transactions sur notre site. Pour plus
      d'informations, <a href="/files/CGU-Emotivi-20201209.pdf">cliquez ici</a>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);

export default {
  name: "App",
  components: {},
  data() {
    return {
      alertcookieshow: false,
      confirmLogoutDialog: false,
      drawer: true,
      nbClientSuivi: 0,
      nbBienSuivi: 0,
      //user: null,
      search: "",
      routeActive: this.$route.meta.menu
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    $route(to, from) {
      this.routeActive = this.$route.meta.menu;
    }
  },
  async created() {
    // if (location.host.indexOf("account") >= 0) {
    //   if (location.pathname.length > 1)
    //     this.$router.push(location.pathname + location.search);
    //   else this.$router.push("/login");
    // } else if (location.host.indexOf("subscription") >= 0) {
    //   if (location.pathname.length > 1)
    //     this.$router.push(location.pathname + location.search);
    //   else this.$router.push("/subscription");
    // } else if (location.host.indexOf("support") >= 0)
    //   this.$router.push("/support");
  },
  async mounted() {
    //window.document.title = "Emotivi Web v" + version;
    let ok = this.$cookie.get("belsunce_allertcookie");
    if (!ok || ok != "ok") this.alertcookieshow = true;
    window.addEventListener("click", this.onWindowClick);
  },
  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  },
  methods: {
    // openPdfCgu() {
    //   window.open(this.$config.server_url + "/web/2.0/cgu", "_blank");
    // },
    logout() {
      this.$store.commit("set_connexion");
      this.$router.push("/");
    },
    logoutAffiliate() {
      this.$store.commit("set_authentification");
      this.$router.push("/login/affiliate");
    },
    gotoPart(page, anchor) {
      this.$router.push("/" + page);
      window.setTimeout(() => {
        if (anchor) {
          let el = document.getElementById(anchor);
          if (el) window.scrollTo(0, el.offsetTop);
        }
      }, 300);
    },
    onWindowClick() {},

    backToMainSite() {
      window.open(this.$config.web_url, "_self");
    },
    goToAccount(path) {
      let urlPath = "/private/home";
      if (path) urlPath = path;
      this.$router.push(urlPath);
    }
  }
};
</script>
<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/styles.scss";

footer {
  background-color: #3a3a3a;
  color: white;
}
.btn-emotivi {
  background-color: rgb(53, 138, 152);
  color: white;
}
// .containercolored {
// }
// @media (min-width: 576px) {
//   .containercolored {
//   }
// }

// Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {
//   .containercolored {
//   }
// }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .containercolored {
    padding-left: 50px;
    padding-right: 50px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .containercolored {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.navbar-brand {
  cursor: pointer;
  margin-right: 0px !important;
}
a.navbar-brand.namePartner {
  font-family: monospace;
  text-transform: uppercase;
  font-size: 2.2vw;
  object-fit: contain;
}
// voir : /node_modules/bootstrap/scss/_variables.scss";
// .container-loader {
//   height: 60vh;
// }
// /********** section du haut ***********/
// .top-top-barre {
//   height: 10px;
//   background-color: #201f1f;
// }
// // top - barre
// // .logo {
// //   // width: 100%;
// //   // max-width: 350px;
// // }
// .container-right {
//   margin-top: 15px;
// }
// .container-coordonees {
//   text-align: center;
// }
// .link-reseau img {
//   width: 8%;
//   margin-top: 8px;
//   margin-bottom: 5px;
// }
// .container-link-reseau {
//   text-align: center;
// }

// @media (min-width: 768px) {
//   .link-reseau {
//     width: 5%;
//   }
// }
// @media (min-width: 992px) {
//   .container-coordonees {
//     text-align: right;
//   }
//   .container-right {
//     margin-top: 0;
//   }
//   .link-reseau {
//     width: 80%;
//     margin-top: 0;
//     margin-bottom: 0;
//   }
//   .link-reseau img {
//     width: 100%;
//     margin-top: 0;
//     margin-bottom: 0;
//     padding: 5px;
//   }
//   .container-link-reseau {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//   }
// }
// // barre de navigation
// .container-nav {
//   background-color: #ed074b;
//   .nav-link {
//     color: #fff !important;
//     font-size: 17px;
//     margin: 0 30px;
//   }
//   .nav-link-lang {
//     margin: 0 20px;
//     a {
//       font-size: 17px;
//       color: #fff;
//       text-decoration: none;
//     }
//     a:hover {
//       color: black;
//     }
//     .dropdown-item {
//       color: #ed074b;
//     }
//   }
// }
// .coeur {
//   width: 15px;
//   height: 15px;
// }
// /************ footer **************/
// footer {
//   background-color: #201f1f;
//   padding: 10px;
//   //margin-top: 100px;
//   a:hover {
//     color: black;
//   }
// }
// .footer-logo {
//   padding-top: 90px;
//   margin: 40px;
// }
// .footer-titre {
//   color: $color-blue2;
//   text-transform: uppercase;
// }
// .alertcookie {
//   max-height: 400px;
//   overflow: auto;
//   position: fixed;
//   background-color: rgba(255, 255, 255, 0.9);
//   padding: 20px 60px;
//   border-top: 3px solid black;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   z-index: 10000;
// }
// .container-right {
//   margin-bottom: 10px;
// }
// .nav-link {
//   color: #fff;
//   font-weight: 300;
//   font-family: $font-nunito;
//   font-size: 20px;
//   padding: 0;
// }
// .nav-link:hover {
//   color: #d6d6d6;
// }
// .link-footer {
//   font-family: $font-nunito;
// }
// @media (min-width: 992px) {
//   .container-right {
//     margin-bottom: 0;
//   }
// }
// .submenu {
//   position: absolute;
//   display: none;
//   z-index: 2;
//   background-color: #ed074b;
//   padding: 30px 15px;
//   color: white;
//   a,
//   a:link,
//   a:visited {
//     cursor: pointer;
//     color: white;
//     text-transform: uppercase;
//   }
// }
</style>
