var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('nav',{staticClass:"navbar  navbar-light",staticStyle:{"border-bottom":"#bdc3c7 solid 0px"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand col",on:{"click":function($event){return _vm.backToMainSite()}}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":"/images/logo_emotivi.png","alt":""}}),_vm._v("   ")]),(
          _vm.$store.state.authenticated &&
            _vm.$store.state.affiliatedUser.establishments[0].es_avatar
        )?_c('a',{staticClass:"navbar-brand col",staticStyle:{"text-align":"center"},on:{"click":function($event){return _vm.backToMainSite()}}},[_c('img',{staticClass:"logoPartner",staticStyle:{"max-width":"200px","width":"100%"},attrs:{"src":("" + (this.$config.server_url) + (_vm.$store.state.affiliatedUser.establishments[0].es_avatar)),"alt":""}})]):_vm._e(),(
          _vm.$store.state.authenticated &&
            !_vm.$store.state.affiliatedUser.establishments[0].es_avatar
        )?_c('a',{staticClass:"navbar-brand namePartner col",staticStyle:{"max-width":"80%"},on:{"click":function($event){return _vm.backToMainSite()}}},[_vm._v(" "+_vm._s(_vm.$store.state.affiliatedUser.establishments[0].es_name)+" ")]):_vm._e(),_c('div',{staticClass:" justify-content-end col",attrs:{"id":"navbarsExample04"}},[(
            _vm.$store.state.connected &&
              _vm.$route.name !== 'subscriptionaffiliate' &&
              _vm.$route.name !== 'loginaffiliate' &&
              _vm.$route.name !== 'subscriptionlimitedperiod'
          )?_c('div',{staticClass:"row justify-content-center",staticStyle:{"min-width":"200px"}},[_c('div',{staticClass:"col-6 col-lg-9 text-right p-1"},[_c('button',{staticClass:"btn btn-xl btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToAccount('/private/home')}}},[_vm._v(" MON COMPTE ")])]),_c('div',{staticClass:"col-6 col-lg-3 p-1"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.logout}},[_c('icon',{staticStyle:{"cursor":"pointer","fill":"white"},attrs:{"scale":"1.2","name":"sign-out-alt"}})],1)])]):_vm._e(),(
            (_vm.$route.name === 'subscriptionaffiliate' ||
              _vm.$route.name === 'loginaffiliate') &&
              _vm.$store.state.authenticated
          )?_c('div',{staticClass:"row justify-content-center",staticStyle:{"min-width":"160px"}},[_c('div',{staticClass:"col text-center"},[_c('button',{staticClass:"btn btn-xl btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.logoutAffiliate()}}},[_vm._v(" Se déconnecter ")])])]):_vm._e()])])]),(_vm.$store.state.authenticated && _vm.$store.state.affiliatedUser)?_c('h1',[_vm._v(" Bonjour cher Partenaire ")]):_vm._e(),_c('div',{},[_c('router-view')],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"none"},attrs:{"id":"wincookie"}},[_c('div',{staticStyle:{"float":"left","cursor":"pointer","margin-right":"20px"}},[_c('a',{attrs:{"href":"javascript:void(0);","onclick":"closeCookieWin();"}},[_c('img',{attrs:{"src":"/images/bt_fermer.png","alt":""}})])]),_vm._v(" En poursuivant votre navigation sans modifier vos paramètres, vous acceptez l'utilisation des cookies ou technologies similaires pour disposer de services et d'offres adaptés à vos centres d'intérêts ainsi que pour la sécurisation des transactions sur notre site. Pour plus d'informations, "),_c('a',{attrs:{"href":"/files/CGU-Emotivi-20201209.pdf"}},[_vm._v("cliquez ici")])])}]

export { render, staticRenderFns }