import Vue from "vue";
// import jQuery from "jquery";
import "vue-awesome/icons/sign-out-alt";
import "vue-awesome/icons/times";
import "vue-awesome/icons/trash";
import "vue-awesome/icons/pen";
import "vue-awesome/icons/question-circle";
import "vue-awesome/icons/bars";
import "vue-awesome/icons/bold";
import "vue-awesome/icons/italic";
import "vue-awesome/icons/tint";
import "vue-awesome/icons/euro-sign";
import "vue-awesome/icons/calendar";
import "vue-awesome/icons/arrow-left";
import "vue-awesome/icons/arrow-right";
import "vue-awesome/icons/arrow-up";
import "vue-awesome/icons/arrow-down";
import "vue-awesome/icons/chevron-left";
import "vue-awesome/icons/chevron-right";
import "vue-awesome/icons/trash-alt";
import "vue-awesome/icons/times-circle";
import "vue-awesome/icons/users";
import "vue-awesome/icons/shopping-basket";
import "vue-awesome/icons/lock";
import "vue-awesome/icons/lock-open";
import "vue-awesome/icons/walking";
import "vue-awesome/icons/car";
import "vue-awesome/icons/heart";
import "vue-awesome/icons/regular/heart";
import "vue-awesome/icons/home";
import "vue-awesome/icons/tv";

//pour la page support
import "vue-awesome/icons/check";
import "vue-awesome/icons/tools";
import "vue-awesome/icons/user-circle";
import "vue-awesome/icons/mobile-alt";
import "vue-awesome/icons/angle-down";
import "vue-awesome/icons/arrow-left";
import "vue-awesome/icons/box-open";

//Modal annulation abonnement
import "vue-awesome/icons/user-times";

//Resultats paiement
import "vue-awesome/icons/check-circle";
import "vue-awesome/icons/exclamation-circle";

//pour la page contact
import "vue-awesome/icons/cogs";
import "vue-awesome/icons/box-open";

//Questionnaire service à la personne
import "vue-awesome/icons/times";

//Page 404
import "vue-awesome/icons/exclamation-triangle";

//Formulaire SEPA
import "vue-awesome/icons/credit-card";
import "vue-awesome/icons/money-check-alt";
import "vue-awesome/icons/euro-sign";

import "vue-awesome/icons/phone-alt";
import "vue-awesome/icons/print";
import "vue-awesome/icons/search";
import "vue-awesome/icons/bed";
import "vue-awesome/icons/map-pin";
import "vue-awesome/icons/building";
import "vue-awesome/icons/vector-square";
import "vue-awesome/icons/sim-card";

// Login page

import "vue-awesome/icons/comments";

import Icon from "vue-awesome/components/Icon";
Vue.component("Icon", Icon);

// import MForm from "../components/MForm";
// Vue.component("m-form", MForm);
// import MFormBase from "../components/MFormBase";
// Vue.component("m-form-base", MFormBase);
import MFormText from "../components/MFormText";
Vue.component("m-form-text", MFormText);
import MFormCombobox from "../components/MFormCombobox";
Vue.component("m-form-combobox", MFormCombobox);
// import MFormRadioGroup from "../components/MFormRadioGroup";
// Vue.component("m-form-radio-group", MFormRadioGroup);
// import MFormRadio from "../components/MFormRadio";
// Vue.component("m-form-radio", MFormRadio);
import MFormCheckbox from "../components/MFormCheckbox";
Vue.component("m-form-checkbox", MFormCheckbox);

// import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
// import datePicker from "vue-bootstrap-datetimepicker";
// Vue.component("date-picker", datePicker);
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import MFormDate from "../components/MFormDate";
Vue.component("m-form-date", MFormDate);
import MFormDateHour from "../components/MFormDateHour";
Vue.component("m-form-date-hour", MFormDateHour);
import MCalendar from "../components/MCalendar";
Vue.component("m-calendar", MCalendar);
import MFormHour from "../components/MFormHour";
Vue.component("m-form-hour", MFormHour);
import MHour from "../components/MHour";
Vue.component("m-hour", MHour);
import MFormSelect from "../components/MFormSelect";
Vue.component("m-form-select", MFormSelect);
import MFormSelectMultiple from "../components/MFormSelectMultiple";
Vue.component("m-form-select-multiple", MFormSelectMultiple);
import MListSimple from "../components/MListSimple";
Vue.component("m-list-simple", MListSimple);
import MFormTextMarkdown from "../components/MFormTextMarkdown";
Vue.component("m-form-text-markdown", MFormTextMarkdown);

import VueRecaptcha from "vue-recaptcha";
Vue.component("vue-recaptcha", VueRecaptcha);

// jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
//   icons: {
//     time: "far fa-clock",
//     date: "far fa-calendar",
//     up: "fas fa-arrow-up",
//     down: "fas fa-arrow-down",
//     previous: "fas fa-chevron-left",
//     next: "fas fa-chevron-right",
//     today: "fas fa-calendar-check",
//     clear: "far fa-trash-alt",
//     close: "far fa-times-circle"
//   }
// });

// import MConfirmDialog from "../components/MConfirmDialog";
// Vue.component("m-confirm-dialog", MConfirmDialog);
// import MMessageDialog from "../components/MMessageDialog";
// Vue.component("m-message-dialog", MMessageDialog);
// import MFormFiles from "../components/MFormFiles";
// Vue.component("m-form-file", MFormFiles);
// import MFormImages from "../components/MFormImages";
// Vue.component("m-form-images", MFormImages);
// import MTagDialog from "../components/MTagDialog";
// Vue.component("m-tag-dialog", MTagDialog);

// import MList from "../components/MList";
// Vue.component("m-list", MList);

// import NavPublic from "../views/NavPublic";
// Vue.component("nav-public", NavPublic);
// import StarRating from "vue-star-rating";
// Vue.component("star-rating", StarRating);

// import ImmoOfferDistances from "../views/ImmoOfferDistances";
// Vue.component("immo-offer-distances", ImmoOfferDistances);
// import ImmoCarouselOffer from "../views/ImmoCarouselOffer";
// Vue.component("immo-carousel-offer", ImmoCarouselOffer);
// import immoCarouselBig from "../views/immoCarouselBig";
// Vue.component("immo-carousel-big", immoCarouselBig);
// import HeaderTitle from "../views/HeaderTitle";
// Vue.component("header-title", HeaderTitle);
// import AccountForm from "../views/AccountForm";
// Vue.component("account-form", AccountForm);

// import LoginModal from "../views/LoginModal";
// Vue.component("login-modal", LoginModal);

Vue.component("loading", { template: "<div>Loading!</div>" });
