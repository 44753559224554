<template>
  <section>
    <div
      class="container pb-5 containercolored"
      style="background-color: rgb(24, 155, 176)"
    >
      <div class="row mb-5">
        <div class="col">
          <div class="row align-items-end">
            <div class="col-md-12 col-lg-5 pt-4">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
              </div>
              <div>
                <h2>MES KITS<br />EMOTIVI</h2>
              </div>
            </div>

            <div class="col-4 col-sm-4 col-lg-2 text-center mb-3 mt-2">
              <h4
                class="part-subtitle-private"
                @click="$router.push('/private/account')"
              >
                MON<br />COMPTE
              </h4>
            </div>
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/payments')"
            >
              <h4 class="part-subtitle-private">MES<br />PAIEMENTS</h4>
            </div>

            <!-- <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchasekey4g')"
            >
              <h4 class="part-subtitle-private">COMMANDER<br />UNE CLE 4G</h4>
            </div> -->
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchaserouter4g')"
            >
              <h4 class="part-subtitle-private">
                COMMANDER<br />UN ROUTEUR 4G
              </h4>
            </div>
          </div>
          <div
            id="documentation"
            class="d-flex pt-4 flex-wrap col"
            style="top: 0; right: 0"
          >
            <div>
              <a
                class="btn btn-primary m-1"
                target="_blank"
                href="/files/Modedemploi_version_web.pdf"
                >DOCUMENTATION INSTALLATION
              </a>
            </div>
            <div>
              <a
                class="btn btn-primary m-1"
                target="_blank"
                href="/files/Emotivi_Aide_Utilisateur.pdf"
                >DOCUMENTATION UTILISATEUR
              </a>
            </div>
            <div>
              <a
                class="btn btn-primary m-1"
                target="_blank"
                href="/files/Modedemploi_routeur_4G.pdf"
                >DOCUMENTATION ROUTEUR 4G
              </a>
            </div>
            <!-- <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=I3KLLo-sQzo"
                  >Comment configurer Emotivi (vidéo)
                </a>
              </div> -->
            <div v-if="depositNotPaid" class="m-1">
              <button
                class="btn btn-danger ml-4 uppercase"
                type="button"
                @click="payDeposit()"
              >
                VOUS N'AVEZ PAS ENCORE PAYÉ LA CAUTION
              </button>
            </div>
            <box-options
              v-if="displayBoxOptions"
              :row_en="selectedEnduser"
              class="m-auto w-100"
              @closeBoxOptions="displayOptions()"
            ></box-options>
            <edit-identifiants
              v-if="displayEditIdentifiants"
              :row_en="selectedEnduser"
              class="m-auto w-100"
              @closeEditIdentifiants="displayEditId()"
            ></edit-identifiants>
          </div>
        </div>
      </div>

      <!-- BOX TILE -->
      <div style="display: block">
        <div class="row" style="color: white">
          <div class="col">
            <h3>
              <icon name="arrow-right" scale="2" fill="white" />
              Mes boxes
            </h3>
            <table class="table table-sm">
              <thead>
                <tr>
                  <div class="row ml-1 mr-1">
                    <th class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                      Commandé le
                    </th>
                    <th class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                      Description
                    </th>
                    <th
                      class="col-lg-2 col-md-4 d-lg-block d-md-block d-sm-none d-xs-none d-none"
                    >
                      Webapp
                    </th>
                    <th
                      class="col-lg-3 d-lg-block d-md-none d-sm-none d-xs-none d-none"
                    >
                      &nbsp;
                    </th>
                    <th
                      class="col-lg-3 d-lg-block d-md-none d-sm-none d-xs-none d-none"
                    >
                      &nbsp;
                    </th>
                  </div>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row_en, indexEn) in getEndusersWithBoxes"
                  :key="indexEn"
                >
                  <div class="row ml-2">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6 p-0">
                      <td v-if="row_en.en_state_subscription === 'SUSPENDED'">
                        {{
                          $moment(row_en.createdAt).format(`DD/MM/YYYY [à]
                        HH:mm`)
                        }}
                        <br /><span
                          style="
                            color: red;
                            font-size: smaller;
                            font-weight: bold;
                          "
                          >Abonnement suspendu</span
                        >
                      </td>
                      <td v-else>
                        {{
                          $moment(row_en.createdAt).format(
                            "DD/MM/YYYY [à] HH:mm"
                          )
                        }}
                      </td>
                    </div>
                    <div
                      class="col col-lg-1 col-md-4 col-sm-6 col-xs-6 col-6 p-0"
                    >
                      <td>Kit Emotivi</td>
                    </div>
                    <div class="col col-lg-3 col-md-4 col-sm-4 col-xs-4 col-12">
                      <td class="">
                        <div
                          @click="showWebappLoginDiv(indexEn)"
                          style="cursor: pointer; user-select: none"
                        >
                          Vos identifiants
                          <icon
                            :id="'angleIcon' + indexEn"
                            name="angle-down"
                            scale="1.2"
                            fill="white"
                          />
                        </div>
                        <transition name="webapplogindiv">
                          <div v-if="showWebappLogin[indexEn]">
                            <i>
                              <b>Identifiant :</b> {{ row_en.en_webapp_login }}
                              <br />
                              <b>Mot de passe :</b>
                              {{ row_en.en_webapp_password }}</i
                            >
                            <button
                              v-if="row_en.bo_id"
                              class="btn btn-primary btn-sm mr-3"
                              type="button"
                              @click="launchWebapp(row_en)"
                            >
                              SE CONNECTER (sur la webapp)
                            </button>
                          </div>
                        </transition>
                      </td>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0">
                      <td class="">
                        <button
                          v-if="row_en.bo_id"
                          class="btn btn-primary btn-sm mr-3"
                          type="button"
                          @click="configureBox(row_en)"
                        >
                          CONFIGURER LE KIT
                        </button>
                        <button
                          v-if="row_en.bo_id"
                          class="btn btn-primary btn-sm mr-3 mt-2"
                          type="button"
                          @click="displayEditId(row_en)"
                        >
                          MODIFIER LES IDENTIFIANTS
                        </button>
                      </td>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0">
                      <td class="text-center">
                        <button
                          v-if="row_en.bo_id"
                          class="btn btn-primary btn-sm mr-3"
                          type="button"
                          @click="redemBox(row_en)"
                        >
                          REDEMARRER LE KIT
                        </button>
                      </td>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0">
                      <td class="text-center">
                        <button
                          v-if="
                            row_en.bo_id &&
                            isBoxCompatible(
                              row_en,
                              'options_value_leds_duration'
                            )
                          "
                          class="btn btn-primary btn-sm mr-3"
                          type="button"
                          @click="displayOptions(row_en)"
                        >
                          LES OPTIONS
                        </button>
                      </td>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import boxOptions from "./BoxOptions.vue";
import editIdentifiants from "./EditIdentifiants.vue";
export default {
  components: { boxOptions, editIdentifiants },
  name: "privatebox",
  data() {
    return {
      dialogErrTxt: "",
      dialogOkTxt: "",
      row_co: {},
      endusers: [],
      parcels: [],
      depositNotPaid: false,
      showWebappLogin: [],
      displayBoxOptions: false,
      selectedEnduser: null,
      displayEditIdentifiants: false,
    };
  },
  async mounted() {
    // efface les données de paiement !!!
    this.$store.commit("set_co_webaccesstoken_temp", "");
  },
  computed: {
    getEndusersWithBoxes() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter((enduser) => {
            return enduser.bo_id;
          })
        : [];
    },
  },

  methods: {
    launchWebapp(row_en) {
      window.open(
        this.$config.webapp_url +
          "/#/autosignin/" +
          row_en.en_accesstoken_fromwebapp,
        "_blank"
      );
    },
    configureBox(row_en) {
      window.open(
        this.$config.config_url +
          "/#/login/" +
          row_en.en_accesstoken_fromconfig,
        "_blank"
      );
    },
    showWebappLoginDiv(index) {
      if (!this.showWebappLogin[index]) {
        this.$$(`#angleIcon${index}`)[0].classList.add("reverseArrow");
        this.$set(this.showWebappLogin, index, true);
      } else {
        this.$$(`#angleIcon${index}`)[0].classList.remove("reverseArrow");
        this.$set(this.showWebappLogin, index, false);
      }
    },

    redemBox(row_en) {
      this.$axios.get(
        this.$config.server_url + "/web/2.0/reboot/" + row_en.en_id
      );
    },
    displayOptions(row_en) {
      if (row_en) this.selectedEnduser = row_en;

      this.displayBoxOptions = !this.displayBoxOptions;
    },
    displayEditId(row_en) {
      if (row_en) this.selectedEnduser = row_en;
      this.displayEditIdentifiants = !this.displayEditIdentifiants;
    },
    /**
     * isBoxCompatible - check if the current box version is compatible with new options
     * @param {Object} row_en
     * @returns {Boolean}
     */
    isBoxCompatible(row_en, withWhat) {
      try {
        return (
          row_en.bo_id.bo_config_options &&
          row_en.bo_id.bo_config_options.hasOwnProperty(withWhat)
        );
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.webapplogindiv-enter-active,
.webapplogindiv-leave-active {
  transition: height 0s;
}
.webapplogindiv-enter, .webapplogindiv-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}
h3 {
  color: white;
}
table {
  td {
    color: white;
    border: none;
    padding-left: 0;
  }
  th {
    color: white;
    padding-left: 7px;
  }
}
.reverseArrow {
  transform: rotate(180deg);
}
</style>
