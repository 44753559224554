import { render, staticRenderFns } from "./MCalendar.vue?vue&type=template&id=ba3e72d6&scoped=true&"
import script from "./MCalendar.vue?vue&type=script&lang=js&"
export * from "./MCalendar.vue?vue&type=script&lang=js&"
import style0 from "./MCalendar.vue?vue&type=style&index=0&id=ba3e72d6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba3e72d6",
  null
  
)

export default component.exports